import React, { Fragment } from "react"

import TwitterIcon from "../../../static/svg/twitter.inline.svg"
import FacebookIcon from "../../../static/svg/facebook.inline.svg"
import LinkedInIcon from "../../../static/svg/linkedin.inline.svg"
import RedditIcon from "../../../static/svg/reddit.inline.svg"
import InstagramIcon from "../../../static/svg/instagram.inline.svg"

import "./styles.css"

const Footer = () => (
  <footer className="footer bg-gray-800 fakit">
    <div className="max-w-7xl mx-auto items-center p-8 flex flex-col lg:flex-row lg:px-4 items-center tracking-wider">
      <div className="pb-4 lg:p-0">
        <a href="https://twitter.com/foreignaffairs" className="float-left m-2 mr-l">
          <TwitterIcon />
          <span className="sr-only">ForeignAffairs.com on Twitter</span>
        </a>
        <a href="https://www.facebook.com/ForeignAffairs" className="float-left m-2">
          <FacebookIcon />
          <span className="sr-only">ForeignAffairs.com on Facebook</span>
        </a>
        <a href="https://www.linkedin.com/company/foreign-affairs-magazine" className="float-left m-2">
          <LinkedInIcon />
          <span className="sr-only">ForeignAffairs.com on LinkedIn</span>
        </a>
        <a href="https://www.reddit.com/user/foreignaffairsmag" className="float-left m-2">
          <RedditIcon />
          <span className="sr-only">ForeignAffairs.com on Reddit</span>
        </a>
        <a href="https://www.instagram.com/foreignaffairsmag/" className="float-left m-2">
          <InstagramIcon />
          <span className="sr-only">ForeignAffairs.com on Instagram</span>
        </a>
      </div>
      <div className="footer--copyright text-white uppercase flex-grow pb-4 lg:p-0">
        <p className="text-center text-xs lg:text-small">
          Copyright &copy; {new Date().getFullYear()} <span className="italic">Foreign Affairs</span>. All
          rights reserved.
        </p>
      </div>
      <div className="footer--visit-fa">
        <a
          href="https://foreignaffairs.com"
          className="float-right text-white uppercase font-bold text-xs lg:text-sm"
        >
          Visit ForeignAffairs.com
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
