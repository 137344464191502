import React, { useState } from "react"

import NavBarLogo from "../../../static/svg/fa-logo.inline.svg"
import Caret from "../../../static/svg/caret.inline.svg"
import AnchorLink from 'react-anchor-link-smooth-scroll'

import "./styles.css"


const NavBar = (props) => {

  const [navOpen, setNavOpen] = useState(false);

  const clickAction = (ev) => {
    ev.currentTarget.closest('.primary-navigation').querySelector('.primary-navigation--list').classList.toggle('active');
    setNavOpen(!navOpen);
  }

  const clickMenu = (ev) => {
    ev.currentTarget.classList.toggle('active');
  }

  const PrimaryLinks = [
    [
      "History",
      "#history"
    ],[
      "Contributors",
      "#contributors"
    ],[
      "Audience",
      "#audience"
    ],[
      "Products",
      "#products"
    ]
  ]

  const SecondaryLinks = [
    [
      "Demographics",
      "/demographics"
    ],[
      "Schedule and Deadlines",
      "/schedule"
    ],[
      "Ad Specs",
      "/techreqs"
    ]
  ]

    return(
      <nav className="flex fixed bg-white z-10 items-center w-full primary-navigation shadow-md sm:py-4 px-5">
        <div className="larger:hidden mobile-menu-btn">
          <input  onChange={clickAction} type="checkbox" checked={navOpen} />
          <span />
          <span />
          <span />
          <div></div>
        </div>
        <a href="/" className="header-logo--link py-4 pl-2">
          <NavBarLogo />
        </a>
        <ul className="hidden larger:flex primary-navigation--list border-0 border-l border-solid pl-2">
          { PrimaryLinks.map((link, i) =>
              <li key={i}>
                { props.home
                  ? <AnchorLink href={ link[1] } onClick={clickAction} offset="100" className="font-bold py-2 px-3 block text-sm hover:blue">{ link[0] }</AnchorLink>
                  : <a href={ "/" + link[1] } offset="100" className="font-bold py-2 px-3 block text-sm hover:blue">{ link[0] }</a>
                }
              </li>
          )}
          <li>
            <a className="font-bold py-2 px-3 block text-sm hover:blue" href="/fa-mediakit-23.pdf">Download Media Kit</a>
          </li>
          <li className="overflow-link font-bold py-2 px-3 block text-sm cursor-pointer" onClick={clickMenu}>More <Caret/>
            <ul className="absolute bg-white overflow-menu border-1 border-solid pt-2 -ml-5">
              { SecondaryLinks.map((link, i) =>
                <li className="border-0 border-b border-solid p-4" key={i}>
                  <a href={ link[1] } className="font-bold hover:blue">{ link[0] }</a>
                </li>
              )}
            </ul>
          </li>
        </ul>
        { props.home
          ? <AnchorLink href="#contact" offset="100" className="block p-2 contact-cta font-semibold text-white absolute right-2 text-sm px-4 sm:px-10 mr-2">Contact</AnchorLink>
          : <a href={ "/" + "#contact"} offset="100" className="block p-2 contact-cta font-semibold text-white absolute right-2 text-sm px-4 sm:px-10 mr-2">Contact</a>
        }
      </nav>
    )

}

export default NavBar
